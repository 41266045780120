import React from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";

function MenuButton({ open, handleClick, gptModel, studentOrNot, blur }) {

  const getBackgroundColor = () => {
    if (!blur) return "#D3D3D3";
    switch (gptModel) {
      case "3.5":
        return "#A730D0";
      case "4":
        return "#218514";
      case "o1-preview":
        return "#ff9900";
      case "o1-mini":
        return "#ff3333";
      case "DeepSeek-R1":
        return "#3399ff";
      case "DeepSeek-V3":
        return "#AE8763";
      default:
        return "#0069b6";
    }
  };

  const getHoverColor = () => {
    switch (gptModel) {
      case "3.5":
        return "#c8a5f8";
      case "4":
        return "rgb(30 162 130)";
      case "o1-preview":
        return "#ffcc00";
      case "o1-mini":
        return "#ff6666";
      case "DeepSeek-R1":
        return "#66ccff";
      case "DeepSeek-V3":
        return "#AE8763";
      default:
        return "#0086e9";
    }
  };

  return (
    <Button
      id="gpt-model-choice"
      name="gpt-model-choice"
      aria-label="gpt-model-choice"
      title="gpt-model-choice"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={handleClick}
      sx={{
        backgroundColor: getBackgroundColor(),
        color: "white",
        borderRadius: "0px 20px 20px 0px",
        height: "41px",
        minWidth: "30px",
        "&:hover": {
          backgroundColor: getHoverColor(),
        },
        "&::after": {
          display: "inline-block",
          marginLeft: "0.255em",
          verticalAlign: "0.255em",
          content: '""',
          borderTop: "0.3em solid",
          borderRight: "0.3em solid transparent",
          borderBottom: 0,
          borderLeft: "0.3em solid transparent",
          color: "white",
        },
      }}
    ></Button>
  );
}

export default React.memo(MenuButton);