import React, { useState, createContext } from "react";

export const ScrollContext = createContext({});

export const ScrollProvider = ({ children }) => {
  const [disableScroll, setDisableScroll] = useState(false);
  const [autoScrolling, setAutoScrolling] = useState(false);

  return (
    <ScrollContext.Provider
      value={{
        disableScroll,
        setDisableScroll,
        autoScrolling,
        setAutoScrolling,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};