export async function streamCompletion(endpoint, options, onDelta, onTokenUsage) {
  // console.log("Starting fetch-based streaming...");
  const response = await fetch(endpoint, options);
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  if (!response.body) throw new Error('ReadableStream not supported in this browser');

  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");
  let leftover = "";

  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      // console.log("Stream reader finished.");
      break;
    }
    const chunk = decoder.decode(value, { stream: true });
    leftover += chunk;
    const lines = leftover.split(/\r?\n/);
    leftover = lines.pop() || "";

    for (const line of lines) {
      if (line.startsWith("data:")) {
        const data = line.replace(/^data:\s*/, "");
        if (data === "[DONE]") {
          // console.log("Received [DONE]. Ending stream.");
          reader.cancel();
          return;
        }
        try {
          const parsed = JSON.parse(data);
          // If choices is an empty array or if the first choice's delta is null,
          // treat it as token usage info and send the whole JSON object.
          if (
            (parsed.choices && (parsed.choices.length === 0 || parsed.choices[0].delta === null)) ||
            parsed.usage
          ) {
            if (onTokenUsage && typeof onTokenUsage === "function") {
              onTokenUsage(parsed);
            }
          } else {
            // Otherwise, handle it as a delta update.
            const deltaContent = parsed.choices?.[0]?.delta?.content || "";
            onDelta(deltaContent);
          }
        } catch (err) {
          console.error("Failed to parse JSON:", line, err);
        }
      }
    }
  }
  if (leftover) {
    // console.log("Leftover after stream ended:", leftover);
  }
}