import React, { memo, useRef, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ChatBox } from "mui-chat-box";
import ChatList from "./ChatList";
import { ChatHistContext } from "../../context/index";
import { ScrollContext } from "../../context/ScrollContext";

const ChatRoom = ({
  userQuestion,
  gptAnwser,
  setFileDialogOpen,
  setFileDialogContent,
}) => {
  const Contents = styled(ChatBox)(() => ({
    flexDirection: "row",
  }));
  const { disableScroll, setAutoScrolling } = useContext(ScrollContext);
  const { gptModel } = useSelector((state) => state.setting);
  const messageRef = useRef();

  useEffect(() => {
    if (!disableScroll && messageRef.current) {
      const blocks = document.querySelectorAll(".block");
      const latestBlock = blocks[blocks.length - 1];
      if (gptModel === "DeepSeek-R1" && latestBlock) {
        // Flag auto-scrolling so manual scroll events are ignored
        setAutoScrolling(true);
        latestBlock.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
        setTimeout(() => {
          setAutoScrolling(false);
        }, 300);
      } else if (latestBlock) {
        latestBlock.scrollIntoView();
      }
    }
  }, [gptAnwser, disableScroll, gptModel, setAutoScrolling]);

  return (
    <Grid container spacing={1} ref={messageRef}>
      <Container
        sx={{
          paddingTop: "60px",
        }}
      >
        <Contents>
          <ChatList
            key={"chatRoom"}
            location={"chatRoom"}
            questions={userQuestion}
            anwsers={gptAnwser}
            setFileDialogOpen={setFileDialogOpen}
            setFileDialogContent={setFileDialogContent}
          />
        </Contents>
      </Container>
    </Grid>
  );
};

export default memo(ChatRoom);
