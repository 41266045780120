import React, { useEffect, useContext, useState, useRef } from "react";
import styled from "@emotion/styled";
import { useMsal } from "@azure/msal-react";
import Textarea from "@mui/joy/Textarea";
import Grid from "@mui/material/Grid";
import { ChatHistContext } from "../../context";
import { useSelector, useDispatch } from "react-redux";
import Attachment from "../Button/Attachment";
import { FileNameContainter } from "../Widget/FileNameContainter";

import {
  setFileName,
  setFileOrNot,
  setFileBase64,
  setDocumentID,
  setExtractedText,
  setFileFormat,
  setFileType,
  setFileSize,
  setFileDialogOpen,
  setFileDialogContent,
  setFileNameRemaining,
} from "../../action";

const Container = styled.div(
  {
    width: "100%",
    border: "1px solid #D3D3D3!important",
    borderRadius: "20px;",
    marginBottom: "15px",
  },
  (props) => ({
    border: props.focusOutline
      ? "2px solid #545454!important"
      : "1px solid #D3D3D3!important",
    background:
      // props.isLoading || props.reachStudentLimit ? "#f9f9f9" : "white",
      props.isLoading ? "#f9f9f9" : "white",
    pointerEvents: 
      // props.isLoading || props.reachStudentLimit ? "none" : "auto",
      props.isLoading ? "none" : "auto",
    marginBottom: props.isFileUploaded ? "15px" : "inherit",
  })
);

export default function TextareaDecorators({
  placeholder,
  questionText,
  setQuestionText,
  setHoverTextArea,
  // reachStudentLimit,
  screenWidth,
}) {
  const { fontSize } = useSelector((state) => state.setting);
  const { fileName, fileSize, fileFormat, fileBase64, fileNameRemaining } = useSelector((state) => state.file);
  const { studentOrNot } = useSelector((state) => state.token);
  const { gptModel } = useSelector((state) => state.setting);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const {
    isLoading,
    submitQuestion,
    setIsLoading,
    setFileUploadStatus,
    isUploading,
    setIsUploading,
    suggestionToTextArea,
    cleanFileContent
  } = useContext(ChatHistContext);
  const [text, setText] = useState("");
  const [focus, setFocus] = useState(false);
  const [trimFileName, setTrimFileName] = useState("");
  const [rowNum, setRowNum] = useState(1);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fileName != "") {
      setTrimFileName(() => {
        let lastIndex = fileName.lastIndexOf("\\");
        let temp = "";
        if (lastIndex >= 0) {
          return (temp = fileName.substring(lastIndex + 1));
        }
      });
    }
  }, [fileName, isLoading]);

  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      if (
        (event.ctrlKey || event.key === "Control") &&
        (event.key == "Enter" || event.keyCode == 10 || event.keyCode == 13)
      ) {
        setText((pre) => {
          let curPos = document.querySelector(
            ".MuiTextarea-textarea"
          ).selectionStart;
          pre = pre.slice(0, curPos) + "\n" + pre.slice(curPos);
          return pre;
        });

        setRowNum((pre) => {
          if (pre < 4) {
            pre += 1;
            return pre;
          } else {
            return 4;
          }
        });
      }
    });
  }, []);

  const submitQuestionHandler = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      if (!event.ctrlKey) {
        if (text.trim() == "") {
          event.preventDefault();
          return;
        }
        //if ((gpt4Confirm == "Yes" && gptModel == "4") || gptModel != "4") {
        let now = new Date();
        let options = { timeZone: "Asia/Hong_Kong" };
        let hongKongTime = now.toLocaleString("en-GB", options);
        if (!isLoading) {
          submitQuestion(questionText, hongKongTime, activeAccount.name);
        }
        //}
        //if (gpt4Confirm == "No" && gptModel == "4") {
        //  //console.log('gpt4 confirm dialogue shown');
        //  setGpt4Dialogue(true);
        //}
      }
    }
  };

  useEffect(() => {
    if (!questionText) {
      setText("");
      setRowNum(1);
    }
  }, [questionText]);

  useEffect(() => {
    if (suggestionToTextArea != "") {
      setText(suggestionToTextArea);
    }
  }, [suggestionToTextArea]);

  useEffect(() => {
    setQuestionText(text);
    if(suggestionToTextArea != "" && text!= ""){
      let now = new Date();
      let options = { timeZone: "Asia/Hong_Kong" };
      let hongKongTime = now.toLocaleString("en-GB", options);
      if (!isLoading) {
        submitQuestion(suggestionToTextArea, hongKongTime, activeAccount.name);
      }
    }
  }, [text]);

  useEffect(() => {
    document.querySelector("#root").addEventListener(
      "click",
      function (e) {
        if (
          e &&
          e.target &&
          e.target.className &&
          typeof e.target.className != "object" &&
          e.target.className.indexOf("JoyTextarea") <= -1
        ) {
          inputRef.current.style = "1px solid #D3D3D3";
        }
      },
      false
    );
  }, []);

  return (
    <>
      <Container
        ref={inputRef}
        focusOutline={focus}
        // reachStudentLimit={reachStudentLimit}
        isLoading={isLoading}
        isFileUploaded={fileName != "" && !isUploading && fileNameRemaining}
      >
        <Textarea
          placeholder={placeholder}
          value={text}
          onChange={(event) => {
            setText(event.target.value);
          }}
          minRows={rowNum}
          maxRows={fileName == "" ? 4 : 4}
          onFocus={(event) => {
            setHoverTextArea(true);
          }}
          onBlur={(event) => {
            setHoverTextArea(false);
            setFocus(false);
          }}
          onClick={(e) => {
            setFocus(true);
          }}
          onKeyPress={submitQuestionHandler}
          sx={{
            minWidth: "100%",
            paddingTop: "10px",
            paddingBottom: "15px",
            boxShadow: "none!important",
            border: "0px!important",
            fontSize:
              // reachStudentLimit && screenWidth < 600
              //   ? fontSize.replace("px", "") * 1 - 6 + "px"
              //   : 
                fontSize.replace("px", "") * 1 - 3 + "px",
            background: 
              // isLoading || reachStudentLimit ? "#f9f9f9" : "white",
              isLoading ? "#f9f9f9" : "white",
            pointerEvents: 
              // isLoading || reachStudentLimit ? "none" : "auto",
              isLoading ? "none" : "auto",
            borderRadius: "20px",
            marginBottom:
              // screenWidth < 375 && reachStudentLimit ? "10px" : 
              "inherit",
            "--Textarea-focusedHighlight": "transparent!important",
          }}
        />
        {fileName != "" && fileNameRemaining && (
          <Grid sx={{ marginLeft: "10px" }}>
            <FileNameContainter
              fileName={trimFileName}
              fileSize={fileSize}
              fileFormat={fileFormat}
              fileBase64={fileBase64}
              setFileDialogOpen={setFileDialogOpen}
              setFileDialogContent={setFileDialogContent}
              clear={() => {
                dispatch(setDocumentID(""));
                dispatch(setExtractedText(""));
                dispatch(setFileName(""));
                dispatch(setFileOrNot(false));
                dispatch(setFileBase64(""));
                dispatch(setFileSize(""));
                dispatch(setFileFormat(""));
                dispatch(setFileType(""));
                dispatch(setFileNameRemaining(false))
              }}
            />
          </Grid>
        )}
      </Container>
      {!studentOrNot && <Attachment
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setFileName={setFileName}
        setFileOrNot={setFileOrNot}
        setFileBase64={setFileBase64}
        setDocumentID={setDocumentID}
        setExtractedText={setExtractedText}
        setFileFormat={setFileFormat}
        setFileType={setFileType}
        setFileSize={setFileSize}
        setFileUploadStatus={setFileUploadStatus}
        setIsUploading={setIsUploading}
        setFileNameRemaining={setFileNameRemaining}
        cleanFileContent={cleanFileContent}
      />}
      {studentOrNot && gptModel === "4o" && <Attachment
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setFileName={setFileName}
        setFileOrNot={setFileOrNot}
        setFileBase64={setFileBase64}
        setDocumentID={setDocumentID}
        setExtractedText={setExtractedText}
        setFileFormat={setFileFormat}
        setFileType={setFileType}
        setFileSize={setFileSize}
        setFileUploadStatus={setFileUploadStatus}
        setIsUploading={setIsUploading}
        setFileNameRemaining={setFileNameRemaining}
        cleanFileContent={cleanFileContent}
      />}
    </>
  );
}
