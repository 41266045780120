import React from 'react';

function Tip() {
  return (
    <div className="loading-tips">
      Tip: Using a reasoning model (DeepSeek-R1) takes more time to generate results. In the result, the reasoning is wrapped by the &lt;think&gt;&lt;/think&gt; tag. If the request times out, please try again. If the response is empty or incomplete, please increase the max response length in the settings and try again.
    </div>
  );
}

export default Tip;