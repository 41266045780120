import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

export default React.memo(Gpt4Warning);

function Gpt4Warning({
  open,
  handleClose,
  submitQuestion,
  setShowAgainGlobal,
}) {
  const { fontSize } = useSelector((state) => state.setting);

  const [showAgain, setShowAgain] = React.useState(true);

  React.useEffect(()=>{
    if (!showAgain) {
      setShowAgainGlobal("Yes");
    }
  },[showAgain])

  return (
    <Dialog
      onClose={() => {
        handleClose(false);
      }}
      open={open}
      sx={{
        fontFamily: "Roboto",
        fontSize: fontSize,
        color: "#9e9e9e",
        maxWidth:"500px",
        margin:"0 auto",
        "& .MuiPaper-root": { borderRadius: "20px", background: "#f9f9f9" },
        "& h2": { fontWeight: "bold", padding: "30px 25px 0px 30px" },
      }}
    >
      <DialogTitle>
        <Grid sx={{ display: "flex", justifyContent: "space-between" , alignItems: "center"}}>
          <Grid sx={{width:"30%", textAlign:"center"}}>
            <img style={{ width: "40%" }} src={"robot.png"} />
          </Grid>
          <Grid sx={{ paddingBottom: "15px" ,width:"70%"}}>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Sending with DeepSeek-R1 (32k)
              </Typography>{" "}
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Sending with DeepSeek-V3 (32k)
              </Typography>{" "}
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Sending with o1-preview (32k)
              </Typography>{" "}
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Sending with o1-mini (32k)
              </Typography>{" "}
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Sending with GPT-4o (32k)
            </Typography>{" "}
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Sending with GPT-4 (32k)
            </Typography>{" "}
            <Typography sx={{ fontSize: "16px", color: "#9E9E9E;" }}>
              You are sending with GPT (32k). <br /> Are you sure?
            </Typography>
            <Grid sx={{ display: "flex", alignItems: "center" }}>
              <Grid>
                <Checkbox
                  onClick={() => {
                    setShowAgain((pre) => !pre);
                  }}
                  sx={{
                    color: "#9E9E9E",
                    paddingLeft: "0px",

                    "&.Mui-checked": {
                      color: "#9E9E9E",
                    },
                  }}
                />
              </Grid>
              <Grid>
                <Typography sx={{ fontSize: "16px", color: "#9E9E9E;" }}>
                  Do not show again
                </Typography>
              </Grid>
            </Grid>
          </Grid>{" "}
          <Grid
            onClick={() => {
              handleClose(false);
            }}
            sx={{ cursor: "pointer", display: "none" }}
          >
            <img src={"/close.svg"} className="App-logo" alt="logo" />
          </Grid>
        </Grid>
      </DialogTitle>
      <List
        sx={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: fontSize.replace("px", "") * 1 - 2 + "px",
          lineHeight: "21px",
          padding: "0px 40px 25px 40px",
          color: "#777",
        }}
      >
        <ListItem disableGutters>
          <Button
            onClick={submitQuestion}
            sx={{
              width: "100%",
              borderRadius: "30px",
              color: "white",
              background: "rgb(30 162 130)",
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              handleClose(false);
            }}
            sx={{
              width: "100%",
              borderRadius: "30px",
              color: "#BDBDBD",
              background: "transparent",
            }}
          >
            Cancel
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}
