import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getTokenResponse } from "../utils/MsGraphApiCall";
import { fetchCompletion } from "../api/fetchCompletion";
import { streamCompletion } from "../api/streamCompletion";
import { checkToken } from "../api/checkToken";
import {
  setTokenAvailable,
  setStudentOrNot,
  setDocumentID,
  setExtractedText,
  setFileName,
  setFileBase64,
  setFileFormat,
  setFileType,
  setFileSize,
  setFileOrNot,
  setFileNameRemaining,
  setSystemMessage,
  setSystemMessageTemplate,
} from "../action";
import delay from "../utils/Delay";
import { useNavigate } from "react-router-dom";
import {
  postDocumentIntelligence,
  getDocumentIntelligence,
} from "../api/fetchDocumentIntelligence";
import { attachingSystemMsg, checkBase64 } from "../utils/Tools";
import MarkdownIt from 'markdown-it';
import { NodeHtmlMarkdown } from 'node-html-markdown';
import { stripHtml } from "string-strip-html";
import { ScrollContext } from "./ScrollContext";
export const ChatHistContext = React.createContext({});

const md = new MarkdownIt();
const nhm = new NodeHtmlMarkdown();

const ChatHistProvider = ({ children }) => {
  const { setDisableScroll } = useContext(ScrollContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    setting,
    systemMessage,
    token: tokenVal,
    file,
  } = useSelector((state) => state);

  const { maxResponse, temperature, topP, passMessageIncluded, gptModel } =
    setting;
  const { content } = systemMessage;
  const { tokenLeft, studentOrNot } = tokenVal;
  //const [fileType, setFileType] = useState('');
  const {
    fileBase64,
    documentID,
    extractedText,
    fileName,
    fileFormat,
    fileType,
    fileSize,
  } = file;
  const [isLoading, setIsLoading] = useState(false);
  const [backToAuth, setBackToAuth] = useState(false);
  const [fetchTokenLoading, setFetchTokenLoading] = useState(true);
  const [userQuestion, setUserQuestion] = useState([]);
  const [gptAnwser, setGptAnswer] = useState([]);
  const [token, setToken] = useState("");
  const [tokenUsed, setTokenUsed] = useState(0);
  const [chatHist, setChatHist] = useState([{}, {}]);
  const [chatEnd, setChatEnd] = useState(false);
  const [chatEndTime, setChatEndTime] = useState("");
  const [showTimeLeftWarning, setTimeLeftWarning] = useState(false);
  const [counterforSkipping, setCounterForSkipping] = useState(-1);
  const [timeLeft, setTimeLeft] = useState(600);
  const [initialStat, setInitialStat] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [errorHappened, setErrorHappened] = useState(false);
  const [actionCount, setActionCount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadStatus, setFileUploadStatus] = useState("");
  const [suggestionToTextArea, setSuggestionToTextArea] = useState("");
  const [noStreamAnswerYet, setNoStreamAnswerYet] = useState(false);

  const cleanFileContent = function(){
    dispatch(setDocumentID(""));
    dispatch(setExtractedText(""));
    dispatch(setFileName(""));
    dispatch(setFileOrNot(false));
    dispatch(setFileBase64(""));
    dispatch(setFileSize(""));
    dispatch(setFileFormat(""));
    dispatch(setFileType(""));
    dispatch(setSystemMessage(""));
    dispatch(setSystemMessageTemplate(""));
  }


  useEffect(() => {
    const fetchToken = async () => {
      const token = await getTokenResponse();
      if (
        token &&
        token.account &&
        token.account.username.indexOf("connect") > -1
      ) {
        dispatch(setStudentOrNot(true));
      } else {
        dispatch(setStudentOrNot(false));
      }
      delay(1000).then(() => {
        setFetchTokenLoading(false);
        setToken(token);
      });
    };
    fetchToken();
  }, []);

  useEffect(() => {
    if (userQuestion.length == 0) {
      setTimeLeft(600);
      setChatEnd(false);
      setChatEndTime("");
    }
    if (chatHist[0].length > 0 && userQuestion.length == 1) {
      let now = new Date();
      let options = { timeZone: "Asia/Hong_Kong" };
      let hongKongTime = now.toLocaleString("en-GB", options);
      setUserQuestion([
        { startChatName: "Chat Start", startChatTime: hongKongTime },
        ...userQuestion,
      ]);
    }
  }, [userQuestion]);

  useEffect(() => {
    let intervalId;
    if (userQuestion.length > 0) {
      if (timeLeft >= 0) {
        intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
      }
    }
    return () => clearInterval(intervalId);
  }, [timeLeft, userQuestion]);

  useEffect(() => {
    if (token && actionCount == 0) {
      let headers = {};
      let bearer = `Bearer ${token.accessToken}`;
      headers.Authorization = bearer;
      headers["Content-Type"] = "application/json";
      let options = { headers, method: "POST" };
      !studentOrNot && dispatch(setTokenAvailable(50000000000));
      studentOrNot &&
        checkToken(process.env.REACT_APP_OPENAI_TOKEN, options).then(
          (response) => {
            dispatch(setTokenAvailable(response.balance));
            setActionCount((prev) => {
              return ++prev;
            });
          }
        );
    }
  }, [token]);

  useEffect(() => {
    async function getDocumentID() {
    if (checkBase64(fileBase64)) {
      if(fileType == "image" || studentOrNot) {
        setIsUploading(true);
        setIsLoading(true);
        setTimeLeft(600);
        dispatch(setDocumentID(fileName));
      }
      else {
        let token = await getTokenResponse();
        let headers = {};
        headers["Content-Type"] = "application/json";
        headers["Type"] = fileFormat;
        headers["Size"] = fileSize;
        headers["Authorization"] = "Bearer " + token.accessToken
        let payload = {};
        payload["base64Source"] = fileBase64;
        let options = {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        };
        setIsUploading(true);
        setIsLoading(true);
        //dispatch(setFileName(""));
        setTimeLeft(600);
        try {
          postDocumentIntelligence(
            process.env.React_APP_DOCUMENT_POST,
            options
          ).then((response) => {
            if (response.status == "notOK") {
              cleanFileContent();
              setFileUploadStatus(response.message);
              return;
            }
            dispatch(setDocumentID(response));
          });
        } catch (e) {
          setFileUploadStatus(e);
        }
      }
      
    }
  }
  getDocumentID();
  }, [fileBase64]);

  useEffect(() => {
    async function extractedTextFunc() {
      if (documentID != "") {
        if(fileType == "image") {
          dispatch(setExtractedText("image"));
          setFileUploadStatus("Image extracted successfully");
        }
        else {
          let token = await getTokenResponse();
          let headers = {};
          headers["Authorization"] = "Bearer " + token.accessToken
          let options = {
            method: "GET",
            headers: headers,
          };
          let extractedStatus = "running";
          let tryCounter = 0;
          while (extractedStatus == "running") {
            try {
              await getDocumentIntelligence(process.env.React_APP_DOCUMENT_GET+documentID+"?api-version=2023-07-31", options).then((text) => {
                extractedStatus = text.status;
                if (text.status.status == "notOK") {
                  cleanFileContent();
                  setFileUploadStatus(text.status.message);
                }
                if (text.status == "succeeded") {
                  dispatch(setExtractedText(text.analyzeResult.content));
                  setFileUploadStatus("Document extracted successfully");
                }
              });
            } catch (e) {
              console.log(e);
              cleanFileContent();
              setFileUploadStatus("Please try again !");
              break;
            }
            if (extractedStatus == "succeeded") {
              break;
            } else {
              tryCounter++;
            }
            if (tryCounter == 6) {
              cleanFileContent();
              setFileUploadStatus("Please try again !");
              break;
            }
            await delay(5000);
          }
        }
      }
    }
    extractedTextFunc();
  }, [documentID]);

  useEffect(() => {
    if (fileUploadStatus != "") {
      setIsLoading(false);
      delay(1000).then(() => {
        setFileUploadStatus("");
        setIsUploading(false);
      });
    }
  }, [fileUploadStatus]);

  useEffect(() => {
    if (timeLeft <= 60) {
      setTimeLeftWarning(true);
    }
    if (timeLeft == -1) {
      setTimeLeftWarning(false);
    }
    if (timeLeft == 0) {
      let now = new Date();
      let options = { timeZone: "Asia/Hong_Kong" };
      let hongKongTime = now.toLocaleString("en-GB", options);

      setChatEnd(true);
      setChatEndTime(hongKongTime);
      setTimeLeftWarning(false);
      setUserQuestion([
        ...userQuestion,
        { endChatName: "Chat ended", endChatTime: hongKongTime },
      ]);
      cleanFileContent();
      setCounterForSkipping(() => {
        //if time out, the preview question(s) will not be submitted to GPT.
        let counter = -1;
        userQuestion.map((q) => {
          if (
            !Object.keys(q).includes("endChatName") &&
            !Object.keys(q).includes("startChatName")
          ) {
            counter++;
          }
        });
        return counter;
      });
    }
  }, [timeLeft]);

  async function callOpenAi(endpoint) {
    let token = await getTokenResponse();
    setToken(token);
    setActionCount((prev) => {
      return ++prev;
    });
    // console.log('endpoint ' + endpoint);
    let headers = {};
    let bearer = `Bearer ${token.accessToken}`;
    headers.Authorization = bearer;
    headers["Content-Type"] = "application/json";
    
    if (gptModel === "DeepSeek-R1") {
      headers["Accept"] = "text/event-stream";
      headers["Cache-Control"] = "no-cache";
      headers["Connection"] = "keep-alive";
      headers["Accept-Encoding"] = "gzip, deflate, br";
    }

    let qustionMsg = [];
    userQuestion.forEach(question => {
      if (
        !question.hasOwnProperty("endChatName") &&
        !question.hasOwnProperty("startChatName")
      ) {
        if (gptModel === "4o" && question.fileBase64 && question.fileType === "image") {
          qustionMsg.push({
            role: "user", content: [
              {
                type: "image_url",
                image_url: {
                  url: `data:image/jpeg;base64,${question.fileBase64}`
                }
              },
            ]
          });
        }
        qustionMsg.push({
          role: "user", content: question.question
        });
      }
    });

    let answerMsg = gptAnwser.map((answer) => {
      return { role: "assistant", content: stripHtml(answer.content, { stripRecognisedHTMLOnly: true }).result };
    });

    let msg = [];
    for (let item in qustionMsg) {
      msg.push(qustionMsg[item]);
      if (typeof answerMsg[item] != "undefined") {
        msg.push(answerMsg[item]);
      }
    }
    msg.slice(counterforSkipping + 1);

    let contentPayload =
      content !== ""
        ? msg.slice((passMessageIncluded - 1) * -1)
        : msg.slice(passMessageIncluded * -1);
    if (gptModel !== "o1-preview" && gptModel !== "o1-mini") {
      if (content !== "" && extractedText === "") {
        contentPayload.unshift({
          role: "system",
          content: content,
        });
      } else if (extractedText !== "") {
        contentPayload.unshift({
          role: "system",
          content: attachingSystemMsg(extractedText),
        });
      }
    } else {
      if (content !== "" && extractedText === "") {
        contentPayload.unshift({
          role: "user",
          content: content,
        });
      } else if (extractedText !== "") {
        contentPayload.unshift({
          role: "user",
          content: attachingSystemMsg(extractedText),
        });
      }
    }

    let options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        model: gptModel === "4o" ? "gpt-4o" :
          gptModel === "4" ? "gpt-4" :
            gptModel === "o1-preview" ? "o1-preview" :
              gptModel === "o1-mini" ? "o1-mini" :
                gptModel === "DeepSeek-R1" ? "DeepSeek-R1" :
                gptModel === "DeepSeek-V3" ? "DeepSeek-V3" :
                  "gpt-35-turbo",
        messages: [
          ...(gptModel !== "o1-preview" && gptModel !== "o1-mini" ? [{
            role: "system",
            content: [
              {
                type: "text",
                text: "You are an AI assistant that helps people find information."
              }
            ]
          }] : []),
          ...contentPayload,
        ],
        ...(gptModel === "o1-preview" || gptModel === "o1-mini"
          ? { max_completion_tokens: tokenLeft < maxResponse ? tokenLeft : maxResponse }
          : (gptModel === "DeepSeek-R1" )
          ? { max_tokens: maxResponse} 
          : { max_tokens: tokenLeft < maxResponse ? tokenLeft : maxResponse }),
        temperature: gptModel === "o1-preview" || gptModel === "o1-mini" ? 1 : temperature,
        ...(gptModel !== "o1-preview" && gptModel !== "o1-mini" && { top_p: topP }),
        ...(gptModel === "DeepSeek-R1" && { stream: true }),
      }),
    };
    setErrorHappened(false);
    setIsLoading(true);
    setGptAnswer([
      ...gptAnwser,
      {
        name: gptModel === "4o" ? "GPT-4o" :
          gptModel === "4" ? "GPT-4" :
            gptModel === "o1-preview" ? "o1-preview" :
              gptModel === "o1-mini" ? "o1-mini" :
                gptModel === "DeepSeek-R1" ? "DeepSeek-R1" :
                gptModel === "DeepSeek-V3" ? "DeepSeek-V3" :
                  "GPT-3.5",
        content: "Loading...",
      },
    ]);

    setTimeLeft(1000);

    let now = new Date();
    let timeZone = { timeZone: "Asia/Hong_Kong" };
    let hongKongTime = now.toLocaleString("en-GB", timeZone);

    if (gptModel === "DeepSeek-R1") {
      try {
        // console.log("Starting DeepSeek-R1 streaming...");
        setNoStreamAnswerYet(true);
        let partialAnswer = {
          name: "DeepSeek-R1",
          content: "",
          dateTime: hongKongTime,
        };

        await streamCompletion(
          endpoint,
          options,
          (deltaContent) => {
            // Process streaming delta content
            partialAnswer.content += deltaContent;
            let tempPartialAnswer = partialAnswer.content
                                      .replaceAll('\\[', '$')
                                      .replaceAll('\\]', '$')
                                      .replaceAll('\\(', '$')
                                      .replaceAll('\\)', '$');
            const partialRendered = md.render(tempPartialAnswer);
            setGptAnswer(prev => {
              const withoutLast = prev.slice(0, -1);
              return [
                ...withoutLast,
                {
                  name: "DeepSeek-R1",
                  content: partialRendered, // Show partial render
                  dateTime: hongKongTime,
                },
              ];
            });
            setNoStreamAnswerYet(false);
            // console.log("setNoStreamAnswerYet to false");
          },
          // (usageObject) => {
          //   // Process token usage info
          //   console.log("Token usage info:", usageObject);
          //   // For example, send the token usage info to another API:
          //   fetch(process.env.REACT_APP_DEEPSEEKR1_LOG, {
          //     method: "POST",
          //     headers: { 
          //       "Content-Type": "application/json",
          //       "Authorization": `Bearer ${token.accessToken}`
          //     },
          //     body: JSON.stringify(usageObject),
          //   })
          //     .then((res) => res.json())
          //     .then((data) => console.log("Received response from token usage API:", data))
          //     .catch((err) => console.error("Error sending token usage info:", err));
          // }
        );

        // console.log("DeepSeek streaming finished.");
        setTimeLeft(600);

        // When the stream is done, do a single final conversion
        let finalMDContent = partialAnswer.content;
        // console.log("Final MD Content:", finalMDContent);
        let tempGptContent = finalMDContent
          .replaceAll('\\[', '$')
          .replaceAll('\\]', '$')
          .replaceAll('\\(', '$')
          .replaceAll('\\)', '$');
        // console.log("Final Temp GPT Content:", tempGptContent);
        let gptContent = md.render(tempGptContent);
        let nowFinal = new Date();
        let hongKongTimeFinal = nowFinal.toLocaleString("en-GB", timeZone);

        setGptAnswer(prev => {
          const updatedData = [
            ...prev.slice(0, -1),
            {
              name: "DeepSeek-R1",
              dateTime: hongKongTimeFinal, 
              isFinal: true,
              content: gptContent, // final rendered
            },
          ];
          return updatedData;
        });
        setIsLoading(false);
        setDisableScroll(false);
        // console.log("enable scroll");

        if (studentOrNot) {
          let tOptions = {
            method: "POST",
            headers: headers,
          };
          checkToken(process.env.REACT_APP_OPENAI_TOKEN, tOptions).then((response) => {
            // console.log("Token check response:", response);
            dispatch(setTokenAvailable(response.balance));
          });
        }
      } catch (error) {
        let nowFinal = new Date();
        let hongKongTimeFinal = nowFinal.toLocaleString("en-GB", timeZone);
        setGptAnswer((prev) => [
          ...prev.slice(0, -1),
          {
            name: "DeepSeek-R1",
            dateTime: hongKongTimeFinal, 
isFinal: true,
            content: "Please try again later",
          },
        ]);
        setBackToAuth(true);
        setErrorHappened(true);
        setApiError(true);
        setIsLoading(false);
        return;
      }
    } else {
      // Non-streaming logic...
      try {
        await fetchCompletion(endpoint, options).then((response) => {
          setTimeLeft(600);
          setGptAnswer(() => {
            return gptAnwser.pop();
          });
          if (!response.choices) {
            if (
              response.statusCode === 429 ||
              response.statusCode === 403 ||
              response.statusCode === 404
            ) {
              let nowFinal = new Date();
        let hongKongTimeFinal = nowFinal.toLocaleString("en-GB", timeZone);
              setGptAnswer([
                ...gptAnwser,
                {
                  name:
                    gptModel === "4o"
                      ? "GPT-4o"
                      : gptModel === "4"
                        ? "GPT-4"
                        : gptModel === "o1-preview"
                          ? "o1-preview"
                          : gptModel === "o1-mini"
                            ? "o1-mini"
                              : gptModel === "DeepSeek-V3"
                              ? "DeepSeek-V3"
                              : "GPT-3.5",
                  dateTime: hongKongTimeFinal, 
isFinal: true,
                  content: response.message + " (" + response.statusCode + ")",
                },
              ]);
              setErrorHappened(true);
              setApiError(true);
            } else if (response.statusCode === 500) {
              let nowFinal = new Date();
        let hongKongTimeFinal = nowFinal.toLocaleString("en-GB", timeZone);
              setGptAnswer([
                ...gptAnwser,
                {
                  name:
                    gptModel === "4o"
                      ? "GPT-4o"
                      : gptModel === "4"
                        ? "GPT-4"
                        : gptModel === "o1-preview"
                          ? "o1-preview"
                          : gptModel === "o1-mini"
                            ? "o1-mini"
                            : gptModel === "DeepSeek-V3"
                              ? "DeepSeek-V3"
                              : "GPT-3.5",
                  dateTime: hongKongTimeFinal, 
isFinal: true,
                  content: "ChatGPT is busy. Please resend the query. (500)",
                },
              ]);
            } else if (response.statusCode === 504) {
              let nowFinal = new Date();
        let hongKongTimeFinal = nowFinal.toLocaleString("en-GB", timeZone);
              setGptAnswer([
                ...gptAnwser,
                {
                  name:
                    gptModel === "4o"
                      ? "GPT-4o"
                      : gptModel === "4"
                        ? "GPT-4"
                        : gptModel === "o1-preview"
                          ? "o1-preview"
                          : gptModel === "o1-mini"
                            ? "o1-mini"
                              : gptModel === "DeepSeek-V3"
                              ? "DeepSeek-V3"
                              : "GPT-3.5",
                  dateTime: hongKongTimeFinal, 
isFinal: true,
                  content: "Request timed out. Please try again. (504)",
                },
              ]);
              setIsLoading(false);
              return;
            } else if (response.statusCode === 401) {
              toast.error("Timeout. Please login again", {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                hideProgressBar: true,
              });
              navigate("/");
              setBackToAuth(true);
            } else {
              let nowFinal = new Date();
        let hongKongTimeFinal = nowFinal.toLocaleString("en-GB", timeZone);
              setGptAnswer([
                ...gptAnwser,
                {
                  name:
                    gptModel === "4o"
                      ? "GPT-4o"
                      : gptModel === "4"
                        ? "GPT-4"
                        : gptModel === "o1-preview"
                          ? "o1-preview"
                          : gptModel === "o1-mini"
                            ? "o1-mini"
                              : gptModel === "DeepSeek-V3"
                              ? "DeepSeek-V3"
                              : "GPT-3.5",
                  dateTime: hongKongTimeFinal, 
isFinal: true,
                  content: response.error.message,
                },
              ]);
              setErrorHappened(true);
              setApiError(true);
            }
            delay(3000).then(() => {
              setIsLoading(false);
            });
            return;
          } else if (response.choices.length < 1) {
            let nowFinal = new Date();
        let hongKongTimeFinal = nowFinal.toLocaleString("en-GB", timeZone);
            setGptAnswer([
              ...gptAnwser,
              {
                name:
                  gptModel === "4o"
                    ? "GPT-4o"
                    : gptModel === "4"
                      ? "GPT-4"
                      : gptModel === "o1-preview"
                        ? "o1-preview"
                        : gptModel === "o1-mini"
                          ? "o1-mini"
                            : gptModel === "DeepSeek-V3"
                            ? "DeepSeek-V3"
                            : "GPT-3.5",
                dateTime: hongKongTimeFinal, 
isFinal: true,
                content: "ChatGPT replies nothing!",
              },
            ]);
            setErrorHappened(true);
            setApiError(true);
            setIsLoading(false);
            delay(3000).then(() => {
              setIsLoading(false);
            });
            return;
          }
          // console.log("response: ", response.choices[0].message.content);
          let tempGptContent = response.choices[0].message.content
                                .replaceAll('\\[', '$')
                                .replaceAll('\\]', '$')
                                .replaceAll('\\(', '$')
                                .replaceAll('\\)', '$');
          let gptContent = md.render(tempGptContent);
          // console.log("gptContent", gptContent);
          setIsLoading(false);
          let nowFinal = new Date();
        let hongKongTimeFinal = nowFinal.toLocaleString("en-GB", timeZone);
          setGptAnswer([
            ...gptAnwser,
            {
              name:
                gptModel === "4o"
                  ? "GPT-4o"
                  : gptModel === "4"
                    ? "GPT-4"
                    : gptModel === "o1-preview"
                      ? "o1-preview"
                      : gptModel === "o1-mini"
                        ? "o1-mini"
                          : gptModel === "DeepSeek-V3"
                          ? "DeepSeek-V3"
                          : "GPT-3.5",
              dateTime: hongKongTimeFinal, 
isFinal: true,
              content: gptContent,
              tokenUsed: studentOrNot
                ? gptModel === "4"
                  ? response.usage.prompt_tokens * 15 +
                    response.usage.completion_tokens * 30
                  : gptModel === "o1-preview"
                    ? Math.floor(response.usage.prompt_tokens * 15 + 0.5) +
                    Math.floor(response.usage.completion_tokens * 45 + 0.5)
                    : gptModel === "o1-mini"
                      ? Math.floor(response.usage.prompt_tokens * 3.75 + 0.5) +
                    Math.floor(response.usage.completion_tokens * 11.25 + 0.5)
                      : gptModel === "4o"
                        ? Math.floor(response.usage.prompt_tokens * 2.5 + 0.5) +
                    Math.floor(
                      response.usage.completion_tokens * 7.5 + 0.5
                    )
                    : gptModel === "DeepSeek-V3"
                    ? Math.floor(response.usage.prompt_tokens * 0.6 + 0.5) +
                    Math.floor(response.usage.completion_tokens * 1.9 + 0.5)
                        : Math.floor(response.usage.prompt_tokens * 2.5 + 0.5) +
                    Math.floor(response.usage.completion_tokens * 7.5 + 0.5)
                : response.usage.total_tokens,
            },
          ]);
          if (studentOrNot) {
            let tOptions = {
              method: "POST",
              headers: headers,
            };
            checkToken(process.env.REACT_APP_OPENAI_TOKEN, tOptions).then(
              (response) => {
                dispatch(setTokenAvailable(response.balance));
              }
            );
          }
        });
      } catch (error) {
        console.log("error in code");
        let nowFinal = new Date();
        let hongKongTimeFinal = nowFinal.toLocaleString("en-GB", timeZone);
        setGptAnswer([
          ...gptAnwser,
          {
            name:
              gptModel === "4o"
                ? "GPT-4o"
                : gptModel === "4"
                  ? "GPT-4"
                  : gptModel === "o1-preview"
                    ? "o1-preview"
                    : gptModel === "o1-mini"
                      ? "o1-mini"
                        : gptModel === "DeepSeek-V3"
                        ? "DeepSeek-V3"
                        : "GPT-3.5",
            dateTime: hongKongTimeFinal, 
isFinal: true,
            content: "Please try again later",
          },
        ]);
        setBackToAuth(true);
        setErrorHappened(true);
        setApiError(true);
        setIsLoading(false);
        return;
      }
    }
  }

  function addToHist() {
    const tempUserQuestion =
      Object.keys(chatHist[0]).length == 0 ? [] : chatHist[0];
    const tempGptAnwser =
      Object.keys(chatHist[1]).length == 0 ? [] : chatHist[1];
    setChatHist([
      [...tempUserQuestion, ...userQuestion],
      [...tempGptAnwser, ...gptAnwser],
    ]);
    setUserQuestion(() => {
      return [];
    });
    setGptAnswer(() => {
      return [];
    });
    setInitialStat(false);
    return;
  }


  function submitQuestion(question, dateTime, name) {
    let questionObj = {};
    questionObj.question = question;
    questionObj.dateTime = dateTime;
    questionObj.name = name;
    //console.log("Test"+question);

    let showFileNameInDialogue = userQuestion.find((item) => {
      return item.documentID == documentID;
    });

    if (documentID != "" && !showFileNameInDialogue) {
      let trimFileName = "";
      let lastIndex = fileName.lastIndexOf("\\");

      if (lastIndex >= 0) {
        trimFileName = fileName.substring(lastIndex + 1);
      }
      questionObj.fileName = trimFileName;
      questionObj.fileBase64 = fileBase64;
      questionObj.fileFormat = fileFormat;
      questionObj.fileType = fileType;
      questionObj.fileSize = fileSize;
      questionObj.documentID = documentID;
    }
    let tempList = [];

    if (initialStat) {
      tempList = [];
      tempList.push({ startChatName: "Chat Start", startChatTime: dateTime });
      tempList.push(questionObj);
      setUserQuestion([...userQuestion, ...tempList]);
    }
    //if the time counter is down to 0, an user send out another question and then a new chat is getting started
    if (timeLeft <= 0) {
      tempList = [];
      tempList.push({ startChatName: "Chat Start", startChatTime: dateTime });
      tempList.push(questionObj);
      setUserQuestion([...userQuestion, ...tempList]);

      //reset the time counter to original value
      setTimeLeft(600);
    } else {
      setUserQuestion([...userQuestion, questionObj]);
    }
    setSuggestionToTextArea("");
    dispatch(setFileNameRemaining(false));
    return;
  }
  //if a user send out the question which means the user question arrays will be changed
  //causing the react state change and then calling openAI API
  useEffect(() => {
    const apiEndpoint = process.env.REACT_APP_GPT; // update endpoint of 3.5-0125
    const apiEndpointGPT4 = process.env.REACT_APP_GPT4;
    const apiEndpointGPT4O = process.env.REACT_APP_GPT4O; //update endpoint of 4o
    const apiEndpointO1Preview = process.env.REACT_APP_O1_PREVIEW; // update endpoint of o1-preview
    const apiEndpointO1Mini = process.env.REACT_APP_O1_MINI; // update endpoint of o1-mini
    const apiEndpointDeepSeekR1 = process.env.REACT_APP_DEEPSEEKR1; // update endpoint of deepseek-r1
    const apiEndpointDeepSeekV3 = process.env.REACT_APP_DEEPSEEKV3; // update endpoint of deepseek-v3
    
    if (
      userQuestion.length > 0 &&
      !Object.keys(userQuestion[userQuestion.length - 1]).includes(
        "endChatName"
      ) &&
      !Object.keys(userQuestion[userQuestion.length - 1]).includes(
        "startChatName"
      )
    ) {
      if (
        (Object.keys(chatHist[0]).length > 0 &&
          !Object.keys(userQuestion[0]).includes("startChatName")) ||
        userQuestion.length > 2 ||
        Object.keys(chatHist[0]).length == 0
      ) {
        // console.log("callOpenAi", gptModel);
        callOpenAi(
          gptModel === "4o" ? apiEndpointGPT4O :
          gptModel === "4" ? apiEndpointGPT4 :
          gptModel === "o1-preview" ? apiEndpointO1Preview :
          gptModel === "o1-mini" ? apiEndpointO1Mini :
          gptModel === "DeepSeek-R1" ? apiEndpointDeepSeekR1 :
          gptModel === "DeepSeek-V3" ? apiEndpointDeepSeekV3 :
          apiEndpoint
          //callOpenAi(gptModel === "4o" ? apiEndpointGPT4O :
                   //gptModel === "4" ? apiEndpointGPT4 : apiEndpoint
        );
      }
    }
  }, [userQuestion]);

  return (
    <ChatHistContext.Provider
      value={{
        isLoading,
        errorHappened,
        fetchTokenLoading,
        backToAuth,
        gptAnwser,
        userQuestion,
        chatHist,
        token,
        tokenUsed,
        chatEnd,
        chatEndTime,
        timeLeft,
        counterforSkipping,
        showTimeLeftWarning,
        isUploading,
        fileUploadStatus,
        suggestionToTextArea,
        setIsLoading,
        setTimeLeft,
        setTimeLeftWarning,
        setFileUploadStatus,
        setUserQuestion,
        submitQuestion,
        addToHist,
        setToken,
        setIsUploading,
        setSuggestionToTextArea,
        cleanFileContent,
        noStreamAnswerYet,
      }}
    >
      {children}
    </ChatHistContext.Provider>
  );
};

export default ChatHistProvider;